










































































import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginFormHeaderLogo from '@/views/auth/components/LoginFormHeaderLogo.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { emailValidator, requiredValidator } from '@/services/defaultFormValidators';
import { VForm } from '@/types/VForm';

@Component({
  components: { LoginFormHeaderLogo },
})
export default class RestorePasswordView extends Vue {
  email: string = '';
  @Inject() apiFacade!: ApiFacade;
  error: Error | null = null;
  isValid: boolean = true;
  isSending: boolean = false;
  isTokenCreatedAndEmailSent: boolean = false;

  $refs!: {
    restorePasswordForm: VForm;
  };

  rules = {
    required: requiredValidator,
    email: emailValidator,
  };

  get errorMessage() {
    return this.error?.message || '';
  }

  async submit() {
    if (!this.$refs.restorePasswordForm.validate()) {
      return;
    }
    try {
      this.isSending = true;
      this.error = null;
      const restoreResult = await this.apiFacade.createRestoreToken(this.email);

      if (restoreResult) {
        this.isTokenCreatedAndEmailSent = true;
        return;
      }

      throw new Error('Ошибка. Попробуйте еще позднее.');
    } catch (err) {
      this.error = err;
      console.error('Login error', err);
    } finally {
      this.isSending = false;
    }
  }

  handleRetry() {
    this.isTokenCreatedAndEmailSent = false;
    this.email = '';
  }
}
